.forget-background {
  background: linear-gradient(240deg, #ffffff47 0%, #3b7de1e0 71%, #1d93d2 100%),
    url(../assets/login-bg.png), 0% 0% no-repeat padding-box;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}
.forget-field-box {
  width: 100%;
  background: transparent linear-gradient(180deg, #1d93d2 0%, #1d93d233 100%) 0%
    0% no-repeat padding-box;
  border-radius: 15px;
  opacity: 1;
}
.forget-gluco-logo {
  width: 200px;
}

.forget-text-font {
  font-size: 1rem;
  font-family: segoe;
}
.forget-form-control {
  display: block;
  font-family: segoe;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.pass-wrapper {
  position: relative;
  display: flex;
  i {
    position: absolute;
    top: 38% !important;
    right: 7% !important;
    color: gray;
  }
  i:hover {
    cursor: pointer;
  }
}
.forget-submit-btn {
  display: inline-block;
  font-family: segoe;
  font-weight: 400;
  color: white;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #1d77d2;
  border: 1px solid #1d77d2;
  padding: 0.375rem 0.75rem;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  width: 100% !important;
}
//Media Quries
@media (min-width: 1920px) and (max-width: 2560px) {
  .forget-field-box {
    padding: 30px 0px 30px 0px;
  }
  .forget-gluco-logo {
    width: 300px;
  }
  .forget-text-font {
    font-size: 1.5rem;
  }
  .forget-submit-btn {
    font-size: 1.7rem;
    padding: 0.375rem 0.75rem;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .forget-form-control {
    font-size: 1.7rem;
    display: block;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    padding: 0.375rem 0.75rem;
    border-radius: 0.5rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}
