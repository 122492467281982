.submit-btn {
  font-family: segoe;
  font-size: 1.1rem;
  display: inline-block;
  font-weight: 400;
  color: white;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #1d77d2;
  border: 1px solid #1d77d2;
  padding: 0.375rem 0.75rem;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  width: 100% !important;
}
.form-patient-control {
  display: block;
  font-family: segoe;
  width: 100%;
  height: calc(1.5em + 0.75rem + 9px);
  padding: 0.375rem 0.75rem;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.cl-filter-btn {
  border-radius: 22px !important;
  font-size: 0.7rem !important;
  font-weight: bold;
  width: 120px;
  height: 43px;

  border: 1px solid #0d92d2 !important;
  border-radius: 20px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;

  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
// -----ChatBox-----
.video-chat-box {
  position: relative;
  width: 100%;
}

.video {
  opacity: 1;
  display: block;
  width: 100%;
  background: rgba(3, 33, 52, 0.7);
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.container:hover .image {
  opacity: 0.3;
}

.container:hover .middle {
  opacity: 1;
}
.search-form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.2rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  // padding-left: 10px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &::placeholder {
    padding-left: 10px;
  }
}
.chat-online {
  color: #34ce57;
}

.chat-offline {
  color: #e4606d;
}

.chat-messages {
  display: flex;
  flex-direction: column;
  max-height: 400px;
  overflow-y: scroll;
}
.contact-list {
  max-height: 400px;
  overflow-y: scroll;
  padding-left: 10px;
}

.chat-message-left,
.chat-message-right {
  display: flex;
  flex-shrink: 0;
}

.chat-message-left {
  margin-right: auto;
}

.chat-message-right {
  flex-direction: row-reverse;
  margin-left: auto;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}
.border-top {
  border-top: none !important;
}
// MOdal

.modal-xl-dialog {
  max-width: 1000px;
  margin: 1.75rem auto;
  /* height: 900px !important; */
  .modal-body {
    height: 80vh;
    overflow-y: scroll;
  }
}
// ---Upcoming Appointments---
.datepicker-dateList {
  display: flex;
  margin: 2px 0 2px 0;
}

.datepicker-date-day-Item {
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  /* margin: 0 12px 0 12px; */
  width: 45px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 50%;
  border: 2px solid #fdfbfb; /* color: $blue-primary; */
}

.datepicker-button-previous-wrapper {
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 0 0 0 12px;
  width: 45px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 50%;
  /* color: $blue-primary; */
}

.datepicker-strip {
  display: flex;
  /*align-items: center;*/
  flex-direction: column;
  margin-top: 10px;
  width: max-content;
  max-width: 100%;
  overflow: hidden;
  line-height: 1.5;
  font-family: sans-serif;
}

.date-day-Item-selected {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 2px solid $blue-primary;
  color: $blue-primary;
}

.datepicker-date-day-Item:hover {
  background-color: $blue-primary;
  border: 2px solid transparent;
  color: #fff;
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.date-day-item-disabled {
  color: rgb(176, 176, 176);
  pointer-events: none;
}

.datepicker-datelist-scrollable {
  display: flex;
  overflow-x: scroll;
  scrollbar-width: none;
  /* margin: 2px 0 2px 0; */
}

.datepicker-datelist-scrollable::-webkit-scrollbar {
  -webkit-appearance: none;
  display: none;
}

.datepicker {
  display: flex;
  margin: 12px;
  align-items: center;
  position: relative;
}

.datepicker-day-label {
  font-size: 12px;
  margin: 4px 0 0 0;
  text-align: center;
}

.scroll-head {
  border: 1px solid #e9e9e9;
  border-radius: 2px;
  margin: 8px 0px 8px 0px;
  text-align: center;
  height: 18px;
  font-size: 12px;
  background: #e9e9e9;
}
.blank-space-div {
  border: 1px solid transparent;
  border-radius: 2px;
  margin: 8px 0px 8px 0px;
  text-align: center;
  height: 18px;
  font-size: 12px;
}

.datepicker-button-previous,
.datepicker-button-next {
  border: none;
  text-decoration: none;
  background: $blue-primary;
  cursor: pointer;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  flex-shrink: 0;
}

.button-previous {
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: 45px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 50%;
  border: 2px solid transparent;
  /* color: $blue-primary; */
}

.datepicker-button-previous {
  transform: rotate(180deg);
}

.datepicker-month-label {
  margin-left: 12px;
  font-size: 15px;
  color: $blue-primary;
  font-weight: bold;
}

.datepicker-date-label {
  /* font-size: 12px; */
  margin-top: -3px;
}
.wrapper {
  position: relative;
  /* border: 1px solid #000; */
}
@-webkit-keyframes ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 0.9;
  }
  100% {
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

@keyframes ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 0.9;
  }
  100% {
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}
.ripple:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: currentColor;
  visibility: hidden;
  z-index: 2;
}
.ripple:not(:active):before {
  -webkit-animation: ripple 0.4s cubic-bezier(0, 0, 0.2, 1);
  animation: ripple 0.4s cubic-bezier(0, 0, 0.2, 1);
  transition: visibility 0.9s step-end;
}
.ripple:active:before {
  visibility: visible;
}
// ---Table---
.patient-list {
  table {
    border-radius: 10px;
  }
  table thead {
    background-color: #003650;
    border-radius: 10px;
    color: white;
  }
  table tbody {
    font-size: 0.8rem;
  }
}
// ---Add Schedule---
.schedule {
  .shedule-date-picker {
    display: block;
    font-family: segoe;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    box-shadow: none !important;
  }
}
//Patient Record
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-contenting {
  display: none;
  position: absolute;
  right: 5%;
  top: 7%;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
