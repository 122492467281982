.registration-background {
  background: linear-gradient(240deg, #ffffff47 0%, #3b7de1e0 71%, #1d93d2 100%),
    url(../assets/registration-bg.png), 0% 0% no-repeat padding-box;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 93vh;
}
.registration-top-bar {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000005c;
  opacity: 1;
}
.registration-glucoRx-logo {
  width: 130px;
}
.registration-create-account-text {
  font-family: segoe;
  font-size: 1.1rem;
  letter-spacing: 1px;
  color: #1d93d2;
  opacity: 1;
}
.registration-voyager-logo {
  width: 200px;
}
.registration-account-heading1 {
  text-align: left;
  font-size: 2rem;
  font-family: segoeBold !important;
  letter-spacing: 0px;
  color: #ffffff;
  text-shadow: 0px 3px 6px #00000029;
  opacity: 1;
}
.registration-account-heading2 {
  text-align: left;
  font: normal normal normal 1.2rem segoe;
  letter-spacing: 0px;
  color: #f6f6f6;
  opacity: 1;
}
.registration-account-field-box {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent linear-gradient(180deg, #1d93d2 0%, #1d93d233 100%) 0%
    0% no-repeat padding-box;
  border-radius: 15px;
  opacity: 1;
}
.registration {
  input[type="date"] {
    display: block !important;
    margin-top: 0px !important;
    font-family: segoe !important;
    width: 100% !important;
    height: calc(1.5em + 0.75rem + 2px) !important;
    padding: 0.375rem 0.75rem !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    color: #495057 !important;
    background-color: #fff !important;
    background-clip: padding-box !important;
    border-radius: 0.25rem !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  }
}
.registration {
  input[type="date"]::placeholder {
    color: red !important;
  }
}

.registration-form-control,
.registration-datePicker {
  display: block;
  font-family: segoe;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;

  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  & ::placeholder {
    color: red !important;
  }
}
.pass-wrapper {
  position: relative;
  display: flex;
  i {
    position: absolute;
    top: 38% !important;
    right: 7% !important;
    color: gray;
  }
  i:hover {
    cursor: pointer;
  }
}
.registration-btn {
  font-family: segoe;
  font-size: 1.1rem;
  display: inline-block;
  font-weight: 400;
  color: white;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #1d77d2;
  border: 1px solid #1d77d2;
  padding: 0.375rem 0.75rem;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  width: 100% !important;
}

.registration-input-labels {
  font-family: segoe !important;
  font-size: 1rem !important;
}
.registration-forget-password {
  font-family: segoe;
  font-size: 1rem;
}
.registration-datePicker {
  width: 100% !important ;
}
.my_checkbox {
  width: 3rem;
  height: 4rem;
}
.registration-terms-condition {
  font-family: segoe;
  font-size: 1rem;
}

.error-color-input {
  border-color: red !important;
}
//Media Quries
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .registration-background {
    height: 100%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .registration-background {
    height: 100%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .registration-background {
    height: 100%;
  }
}

/* Large devices (laptops/desktops, 1400px and up) */
@media only screen and (min-width: 1400px) {
  .registration-background {
    // height: 93vh;
  }
  .registration-glucoRx-logo {
    width: 130px;
  }
  .registration-create-account-text {
    font-size: 1.1rem;
  }
  .registration-voyager-logo {
    width: 200px;
  }
  .registration-account-heading1 {
    font-size: 2rem;
  }
  .registration-account-heading2 {
    font: normal normal normal 1.2rem segoe;
  }
  .registration-form-control {
    height: calc(1.5em + 0.75rem + 2px);
  }
  .registration-btn {
    font-size: 1.2rem;
  }

  .registration-input-labels {
    font-size: 1.2rem !important;
  }
}

@media only screen and (max-width: 1920) {
  .registration-background {
    // height: 93vh;
  }
  .registration-glucoRx-logo {
    width: 180px;
  }
  .registration-create-account-text {
    font-size: 2.5rem;
  }
  .registration-voyager-logo {
    width: 300px;
  }
  .registration-account-heading1 {
    text-align: left;
    font-size: 2.8rem;
  }
  .registration-account-heading2 {
    font: normal normal normal 1.8rem segoe;
  }
  .registration-form-control {
    height: 2rem;
    font-size: 1rem;
  }
  .registration-btn {
    font-size: 1.5rem;
  }

  .registration-input-labels {
    font-size: 2rem !important;
  }
}

/* Extra large devices (large laptops and desktops, 1400px and up) */
@media only screen and (min-width: 1921px) {
  .registration-background {
    // height: 93vh;
  }
  .registration-glucoRx-logo {
    width: 200px;
  }
  .registration-create-account-text {
    font-size: 2.5rem;
  }
  .registration-voyager-logo {
    width: 400px;
  }
  .registration-account-heading1 {
    text-align: left;
    font-size: 4rem;
  }
  .registration-account-heading2 {
    font: normal normal normal 2.5rem segoe;
  }
  .registration-form-control,
  .registration-datePicker {
    height: 4rem;
    font-size: 2rem;
  }
  .registration {
    input[type="date"] {
      display: block !important;
      margin-top: 0px !important;
      font-family: segoe !important;
      width: 100% !important;
      height: 4rem !important;
      padding: 0.375rem 0.75rem !important;
      font-size: 1rem !important;
      font-weight: 2rem !important;
      line-height: 1.5 !important;
      color: #495057 !important;
      background-color: #fff !important;
      background-clip: padding-box !important;

      border-radius: 0.25rem !important;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
    }
  }

  .registration-btn {
    font-size: 2rem;
  }

  .registration-input-labels {
    font-size: 2rem !important;
  }
  .registration-terms-condition {
    font-size: 1.5rem;
  }
}
