// /************** Tool Tip Styles ********************************/
// .tool-tip {
//   display: inline-block;
//   position: relative;
//   margin-left: 0.5em;
// }
// .tool-tip .tool-tip__icon {
//   background: #27b1f0;
//   border-radius: 10px;
//   cursor: pointer;
//   display: inline-block;
//   font-style: italic;
//   font-family: times new roman;
//   height: 20px;
//   line-height: 1.3em;
//   text-align: center;
//   width: 20px;
// }
// .tool-tip .tool-tip__info {
//   display: none;
//   background: #262626;
//   border: 1px solid #27b1f0;
//   border-radius: 3px;
//   font-size: 0.875em;
//   padding: 1em;
//   position: absolute;
//   left: 30px;
//   top: -20px;
//   width: 250px;
//   z-index: 2;
// }
// .tool-tip .tool-tip__info:before,
// .tool-tip .tool-tip__info:after {
//   content: "";
//   position: absolute;
//   //   left: -10px;
//   top: 7px;
//   border-style: solid;
//   border-width: 10px 10px 10px 0;
//   border-color: transparent #27b1f0;
// }
// .tool-tip .tool-tip__info:after {
//   //   left: -8px;
//   border-right-color: #262626;
// }
// .tool-tip .tool-tip__info .info {
//   display: block;
// }
// .tool-tip .tool-tip__info .info__title {
//   color: #fa00f3;
// }
// .tool-tip:hover .tool-tip__info,
// .tool-tip:focus .tool-tip__info {
//   display: inline-block;
// }

// a:focus + .tool-tip .tool-tip__info {
//   display: inline-block;
// }

// .tooltip {

//   &:hover::before {
//     content: "";
//     border: solid transparent;
//     border-bottom-color: white;
//     color: black;
//     border-width: 10px;
//     position: absolute;
//     top: 20px;
//   }

//   &:hover::after {
//     content: attr(data-tooltip);
//     position: absolute;
//     min-width: 15em;
//     font-weight: 100;
//     line-height: 1.3em;
//     margin: 0;
//     background: #4862a3;
//     color: #fff;
//     padding: 15px;
//     border-radius: 5px;
//     right: 10%;
//     top: 40px;
//     z-index: 1;
//     font-size: 0.7em;
//   }
// }

.tooltipp {
  position: relative;
  display: inline-block;
  font-size: 0.98rem;
  .tooltipptext {
    visibility: hidden;
    width: 10rem;
    background-color: white;
    border: 2px solid;
    color: black;
    font-size: 1rem;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 100;
    top: 120%;
    right: 30%;
    //   margin-left: -60px;
    margin-right: -45px;
    &::after{

        content: "";
        position: absolute;
        bottom: 100%;
        left: 67%;

        margin-left: -5px;
        border-width: 10px;
        border-style: solid;
        border-color: transparent transparent #ad3860 transparent;

    }

  }
  &:hover{
    .tooltipptext {
      visibility: visible;
    }
  }
}
