.top-nav-link {
  color: #ffffff;
  background: #1b5c87;
  width: 145px;
  // min-height: 57px;

  & :hover {
    color: white !important;
  }
  & :focus {
  }
  & :active {
    background: #527c92 !important;
    height: 100%;
  }
  .active {
    text-align: center;
    width: 100%;
    height: 57px;
    background: #527c92 !important;
    color: white;
  }
  a {
    width: 100%;
    height: 57px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.dropdown-option {
  color: #ffffff;
  background: #1b5c87;

  & :hover {
    color: white !important;
  }
  & :focus {
  }
  & :active {
    background: #527c92 !important;
    height: 100%;
  }
  .active {
    text-align: center;
    width: 100%;
    height: 100%;
    background: #527c92 !important;
    color: white;
  }
  // a {
  //   width: 100%;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  // }
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 255, 255, 255.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

//Media Quries
// @media (min-width: 1024px) {
//   .navbar-expand-lg .navbar-collapse {
//     display: -ms-flexbox !important;
//     display: flex !important;
//     -ms-flex-preferred-size: auto;
//     flex-basis: auto;
//   }
// }
@media (min-width: 1025px) {
  .navbar-expand-lg .navbar-toggler {
    display: none !important;
  }
}
@media (max-width: 1024px) {
  .navbar-expand-lg .navbar-toggler {
    display: block !important;
  }
}
// @media (max-width: 1024px) {
//   .navbar-expand-lg .navbar-collapse {
//     display: none !important;
//   }
// }

// @media (min-width: 1025px) {
//   .navbar-expand-lg .navbar-collapse {
//     display: -ms-flexbox !important;
//     display: flex !important;
//     -ms-flex-preferred-size: auto;
//     flex-basis: auto;
//   }
// }
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 1024px) {
  .top-nav-link {
    color: #ffffff;
    background: #1b5c87;
    width: 100%;

    img {
      margin-left: 33%;
    }
    i {
      margin-left: 33%;
    }
    a {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: left;
    }
  }
  .more-apps {
    display: none;
  }
}

.dropdown-toggler[aria-expanded="true"] {
  transform: rotate(90deg);
}

/*for the animation*/
.dropdown-toggler {
  transition: 0.5s;
}
