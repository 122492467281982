$dark-blue: rgb(33, 96, 138);
.share {
  background-image: url("../assets/share.png");
  background-size: cover;
  height: 100%;
}

.share-comp-bg {
  background-image: url(../assets/share-comp-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.5;
  .btn-share-category {
    background-color: $dark-blue;
    font-size: 1.5rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .text-decoration-underline {
    text-decoration: underline;
  }
}

.accordian-btn {
  background-color: $dark-blue;
  width: 12em;
  font-weight: bold;
  color: #fff;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 1.5rem;
  padding: 0px 10px 0px 10px;
  transition: all 0.9s ease-out;
}
.accordian-btn-active {
  background-color: $dark-blue;
  width: 100%;
  font-weight: bold;
  color: #fff;
  font-size: 1.5rem;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 0px 10px 0px 10px;
  transition: all 0.9s ease-out;
}
.shareTable {
  // sets

  $gl-ms: "screen and (max-width: 23.5em)"; // up to 360px
  $gl-xs: "screen and (max-width: 35.5em)"; // up to 568px
  $gl-sm: "screen and (max-width: 48em)"; // max 768px
  $gl-md: "screen and (max-width: 64em)"; // max 1024px
  $gl-lg: "screen and (max-width: 80em)"; // max 1280px

  // table style

  table {
    border-spacing: 1;
    border-collapse: collapse;
    background: white;
    border-radius: 6px;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    position: relative;
    $breakpoint-tablet: 768px;
    @media #{$gl-md} {
      * {
        position: relative;
      }
    }

    td,
    th {
      padding-left: 8px;
    }

    thead tr {
      height: 60px;
      background: rgb(89, 145, 182);
      font-size: 16px;
      color: #e3f1d5;
    }

    tbody tr {
      height: 48px;
      border-bottom: 1px solid #e3f1d5;
      &:last-child {
        border: 0;
      }
    }

    td,
    th {
      text-align: left;
      &.l {
        text-align: right;
      }
      &.c {
        text-align: center;
      }
      &.r {
        text-align: center;
      }
    }
  }

  @media #{$gl-xs} {
    table {
      display: block;
      > *,
      tr,
      td,
      th {
        display: block;
      }

      thead {
        display: none;
      }
      tbody tr {
        height: auto;
        padding: 8px 0;
        td {
          padding-left: 45%;
          margin-bottom: 12px;
          &:last-child {
            margin-bottom: 0;
          }
          &:before {
            position: absolute;
            font-weight: 700;
            width: 40%;
            left: 10px;
            top: 0;
          }

          &:nth-child(1):before {
            content: "firstName";
          }
          &:nth-child(2):before {
            content: "LastName";
          }
          &:nth-child(3):before {
            content: "Email";
          }
          &:nth-child(4):before {
            content: "Action";
          }
          // &:nth-child(5):before {
          //   content: "Box Type";
          // }
        }
      }
    }
  }
}
