.login-section {
  height: 100vh;

  .login-background {
  background: linear-gradient(240deg, #ffffff47 0%, #3b7de1e0 71%, #1d93d2 100%),
    url(../assets/login-bg.png), 0% 0% no-repeat padding-box;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100%;
}
.login-top-bar {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000005c;
  opacity: 1;
}
.login-form {
  margin-top: 3%;
}
.login-glucoRx-logo {
  width: 130px;
}
.login-create-account-text {
  font-family: segoe;
  font-size: 1.1rem;
  letter-spacing: 1px;
  color: #1d93d2;
  opacity: 1;
}
.login-voyager-logo {
  width: 200px;
}
.login-account-heading1 {
  text-align: left;
  font-size: 2rem;
  font-family: segoeBold;
  letter-spacing: 0px;
  color: #ffffff;
  text-shadow: 0px 3px 6px #00000029;
  opacity: 1;
}
.login-account-heading2 {
  text-align: left;
  font: normal normal normal 1.2rem segoe;
  letter-spacing: 0px;
  color: #f6f6f6;
  opacity: 1;
}
.login-account-field-box {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent linear-gradient(180deg, #1d93d2 0%, #1d93d233 100%) 0%
    0% no-repeat padding-box;
  border-radius: 15px;
  opacity: 1;
}
.login-account-field-box1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #1d93d2;
    border-radius: 15px;
    opacity: 1;
}
.login-form-control {
  display: block;
  font-family: segoe;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.login-btn {
  font-family: segoe;
  font-size: 1.1rem;
  display: inline-block;
  font-weight: 400;
  color: white;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #1d77d2;
  border: 1px solid #1d77d2;
  padding: 0.375rem 0.75rem;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  width: 100% !important;
  opacity: 1 !important;
}

.login-input-labels {
  font-family: segoe !important;
  font-size: 1rem !important;
}
.login-forget-password {
  font-family: segoe;
  font-size: 1rem;
}

//Media Quries
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  // .login-background {
  //   height: 100%;
  // }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  // .login-background {
  //   height: 100%;
  // }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  // .login-background {
  //   height: 100%;
  // }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  // .login-background {
  //   height: 93vh;
  // }
  .login-glucoRx-logo {
    width: 130px;
  }
  .login-create-account-text {
    font-size: 1.1rem;
  }
  .login-voyager-logo {
    width: 200px;
  }
  .login-account-heading1 {
    font-size: 2rem;
  }
  .login-account-heading2 {
    font: normal normal normal 1.2rem segoe;
  }
  .login-form-control {
    height: calc(1.5em + 0.75rem + 2px);
  }
  .login-btn {
    font-size: 1.2rem;
  }

  .login-input-labels {
    font-size: 1rem !important;
  }
  .login-forget-password {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 1920) {
  // .login-background {
  //   height: 93vh;
  // }
  .login-glucoRx-logo {
    width: 180px;
  }
  .login-create-account-text {
    font-size: 2.5rem;
  }
  .login-voyager-logo {
    width: 300px;
  }
  .login-account-heading1 {
    text-align: left;
    font-size: 2.5rem;
  }
  .login-account-heading2 {
    font: normal normal normal 1.5rem segoe;
  }
  .login-form-control {
    height: 2rem;

    font-size: 1rem;
  }
  .login-btn {
    font-size: 1.5rem;
  }

  .login-input-labels {
    font-size: 1.7rem !important;
  }
  .login-forget-password {
    font-size: 1.7rem;
  }
}

/* Extra large devices (large laptops and desktops, 1400px and up) */
@media only screen and (min-width: 1921px) {
  // .login-background {
  //   height: 93vh;
  // }
  .login-glucoRx-logo {
    width: 200px;
  }
  .login-create-account-text {
    font-size: 2.5rem;
  }
  .login-voyager-logo {
    width: 400px;
  }
  .login-account-heading1 {
    text-align: left;
    font-size: 4rem;
  }
  .login-account-heading2 {
    font: normal normal normal 2.5rem segoe;
  }
  .login-form-control {
    height: 4rem;

    font-size: 2rem;
  }
  .login-btn {
    font-size: 2rem;
  }

  .login-input-labels {
    font-size: 2rem !important;
  }
  .login-forget-password {
    font-size: 2rem;
  }
}
}
