//Font Size
.font-size-small {
  font-size: 0.5rem !important;
}
.font-size-small-1 {
  font-size: 0.7rem !important;
}
.font-size-normal {
  font-size: 1rem !important;
}
.font-size-big {
  font-size: 1.2rem !important;
}
.font-size1 {
  font-size: 1.5rem !important;
}
.font-size2 {
  font-size: 2rem !important;
}
.font-size2-half {
  font-size: 2.5rem !important;
}
.font-size3 {
  font-size: 3rem !important;
}
.font-size4 {
  font-size: 4rem !important;
}

a {
  list-style: none !important;
  color: inherit !important;
  & :focus {
    outline: none !important;
  }
}
//Navigation background color
.nav-bg-color {
  color: $navbar-bg-blue !important;
}
//Background Colors and text color classes
.bg-focus {
  background-color: #527c92 !important;
}
.bg-light-gray {
  background-color: #c0c0c0;
}
.bg-voyger-blue {
  background-color: $blue-primary;
}
//Blood glucose theme color
.bg-voyger-purple {
  background-color: $purple-primary;
}
//Blood glucose theme color
.bg-voyger-inr-blue {
  background-color: $inr-blue;
}
//Blood pressure theme color
.bg-voyger-bp-primary {
  background-color: $bp-primary;
}
.text-voyger-blue {
  color: $blue-primary;
}

//Blood glucose theme text color
.text-voyger-purple {
  color: $purple-primary;
}
//Blood glucose theme text color

.text-voyger-inr-blue {
  color: $inr-blue;
}
//Blood pressure theme text color

.text-voyger-bp-purple {
  color: $bp-primary;
}
.div-border-radius {
  border-radius: 20px !important;
}

//Border-Color

//Blood glucose theme border color
.border-voyger-purple {
  border-color: $purple-primary;
}
//Blood glucose theme border color

.border-voyger-inr-blue {
  border-color: $inr-blue;
}
//Blood pressure theme border color
.border-voyger-bp-purple {
  border-color: $bp-primary;
}

//TextArea
textarea {
  resize: none !important;
}

button :focus {
  outline: 0 !important;
}
button:focus:not(:focus-visible) {
  outline: none !important;
  outline: 0px transparent !important;
}
input:focus {
  outline: 0;
}
button:focus {
  outline: 0;
}
button :focus {
  // outline: none !important;
  outline: 0px transparent !important;
}
.bg-filter-btn:focus {
  outline: 0 !important;

  color: white !important;
  background-color: $blue-primary !important;
  border: 1px solid $blue-primary !important;
  border-radius: 20px !important;
  // padding: 8px 25px 8px 25px !important;
  // font-size: 10px !important;
}
.bg-filter-btn {
  color: $blue-primary !important;
  background-color: white !important;
  border: 1px solid $blue-primary !important;
  // border-radius: 20px !important;
  // padding-left: 25px !important;
  // padding-right: 25px !important;
  // font-size: 10px !important;
  // padding-top: 8px !important;
  // padding-bottom: 8px !important;

  @media only screen and (max-width: 768px) {
    .bg-filter-btn {
      color: $blue-primary !important;
      background-color: white !important;
      border: 1px solid $blue-primary !important;
      border-radius: 20px !important;
      // padding-left: 25px !important;
      // padding-right: 25px !important;
      // font-size: 10px !important;
      // padding-top: 8px !important;
      // padding-bottom: 8px !important;
    }
  }
}
.bg-filter-btn-vital {
  color: $blue-primary !important;
  background-color: white !important;
  border: 1px solid $blue-primary !important;
  border-radius: 20px !important;
  padding-left: 13px !important;
  padding-right: 13px !important;
  font-size: 10px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;

  &:focus {
    outline: 0 !important;

    color: white !important;
    background-color: $blue-primary !important;
    border: 1px solid $blue-primary !important;
    border-radius: 20px !important;
    padding: 8px 13px 8px 13px !important;
    font-size: 10px !important;
  }
  @media only screen and (max-width: 600px) {
    color: $blue-primary !important;
    background-color: white !important;
    border-color: $blue-primary !important;
    border-radius: 20px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
.select-active {
  --bg-opacity: 1 !important;
  background-color: #2b6cb0 !important;
  background-color: rgba(43, 108, 176, var(--bg-opacity)) !important;
  color: white !important;
}
.actual-data {
  overflow-y: scroll;
  max-height: 95%;
}
a {
  list-style: none !important;
  color: inherit !important;
  & :focus {
    outline: none !important;
  }
}
.tackle-it {
  & > div:first-of-type {
    height: 100% !important;
  }
}
.set-height {
  max-height: 534px;
}

.filter-area {
  padding: 1%;
  border-radius: 50px;
  background: #ffffff;
}
.feedback {
  min-height: 300px;
}
.bp-stats {
}
.bp-box {
  max-width: 166px;
  height: 166px;
}

.bp-1 {
  .heading {
    color: #3122a5;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    font-weight: bold;
    background-color: rgba(49, 34, 165, 0.1);
  }
  .card-body {
    font-size: 1.3rem;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    color: white;
    background-color: #3122a5;
  }
}
.bp-2 {
  .heading {
    color: #ea7b85;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    font-weight: bold;
    background-color: rgba(234, 123, 133, 0.1);
  }
  .card-body {
    font-size: 1.3rem;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    color: white;
    background-color: #ea7b85;
  }
}
.bp-3 {
  .heading {
    color: #e4be7f;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    font-weight: bold;
    background-color: rgba(228, 190, 127, 0.1);
  }
  .card-body {
    font-size: 1.3rem;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    color: white;
    background-color: #e4be7f;
  }
}
.bp-4 {
  .heading {
    color: #53a0e4;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    font-weight: bold;
    background-color: rgba(83, 160, 228, 0.1);
  }
  .card-body {
    font-size: 1.3rem;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    color: white;
    background-color: #53a0e4;
  }
}

.bg-history {
  // padding: 0 3rem 3rem 3rem;
  height: 100%;
  background: white;
  width: 100%;
}
.guide {
  table {
    border-radius: unset !important;
  }
}
.bp-chart {
  border-radius: unset !important;

  th {
    border-radius: unset !important;
    background: #505052;
    color: white;
    vertical-align: inherit;
    border-right: 1px solid white;
  }
  td {
    padding: 0.49rem;
  }
  .normal {
    td {
      background: #a6ce3a;
      color: black;
    }
  }
  .elev {
    td {
      background: #ffec34;
      color: black;
    }
  }
  .stage-1 {
    td {
      background: #f7b532;
      color: black;
    }
  }
  .stage-2 {
    td {
      background: #bb3b23;
      color: white;
    }
  }
  .crisis {
    td {
      background: #9a2a1b;
      color: white;
    }
  }
}
.bp-chart {
  min-height: 461px;
  tr {
    th,
    td {
      vertical-align: middle;
    }
  }
}

.bp-readings {
  .ant-table-thead > tr > th {
    background: #3193d2;
  }
}
.bp {
  p {
    font-size: 18px;
    margin-bottom: 0;
  }
}
.po-meter {
  tr {
    th {
      background: white;
      font-weight: bold;
      color: black !important;
    }
  }
}
// .ant-layout {
//   min-height: 100vh !important;
// }
// .scrolling-fix {
//   overflow: auto;
//   main {
//     overflow: unset !important;
//   }
// }

.printing-screen {
  display: flex;
  margin: auto;
  width: 80%;
}

@media print {
  .print-btn {
    display: none;
  }
  .printing-screen {
    display: flex;
    width: 100%;
    margin: none;
  }
}
.pdf-img {
  width: 35px;
  padding-left: 8px;
}

@media (max-width: 768px) {
  .grx-main-mobile {
    height: none;
    max-height: calc(100vh - 76px);
    overflow-y: auto;
  }
}
@media (min-width: 1025px) {
  .grx-main-mobile {
    height: 100vh;
  }
  .grx-main {
    max-height: calc(100vh - 76px);
    overflow-y: auto;
  }
}
//Pagination
.ant-pagination-prev,
.ant-pagination-disabled,
.ant-pagination-next {
  button {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
}
.z-ind {
  z-index: 99;
}
.bg-readings {
  table{
  margin-top: 11%;
}
}
.ant-table-pagination.ant-pagination {
    margin: 16px 0 -35px 0;
}
h6 {
  padding-bottom: 2%;
}
.fade {
  // z-index: 0
}
.pdf-back {
  background: #003650;
}
.export-it {
  background: transparent;
  border: 1px solid white;
  color: white;
}
.selected {
  border: 1px solid white;
border-radius: 5px;
opacity: 1;
}
.not-selected {
  border: 1px solid white;
border-radius: 5px;
opacity: 0.4;
}
.file-upload {
  min-width: 475px;
  min-height: 230px;
  background-image: url(../assets/upload.png);
  background-repeat: no-repeat;
  // background-size: 20% 20%;
  background-position: center center;
  background-size: 100px;
}

input[type="file"] {
    display: none;
}
.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    margin-bottom: 1rem;
}
.actions-tab {
  justify-content: space-evenly;
}
.d-grid{
  display: grid !important;
}
.w-fit{
  width: fit-content;
}
.upload-image {
      margin-bottom: .5rem;
}
.comment-block {
  min-width: 475px;
  min-height: 230px;
}
.add-pat{
  // background-image: url('../assets/doc-pat.jpeg');
  // background-image: url('../assets/addpatient.jpg');
  background-image: url('../assets/addpatient.png');


  background-repeat: no-repeat;
  background-size: cover;
}

.modal-backdrop {
  z-index: -1;
}
@media (prefers-reduced-motion: reduce) {
        .modal.fade .modal-dialog {
            transition: none;
        }
    }
.modal { overflow: auto !important; }

.loading {
  // min-height: 375px;
  margin: auto;
  border-radius: 50px;
  background: #007bff !important;
  .grx-logo{
    margin-top: 1%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
    // background: #fff;
    padding: 2%;
  }
  p{
    color: white;
    font-weight: bolder;

  }
}
