.alexa {
  @media only screen and(max-width: 1024px){
    height: 92vh !important;
  }
  &-header {
    background: #fff 0 0 no-repeat padding-box;
    box-shadow: 0 3px 6px RGB(0 0 0 / 36%);
    opacity: 1;

  }

  &-content {
    height: 93vh !important;
    background: linear-gradient(240deg, #ffffff47 0%, #3b7de1e0 71%, #1d93d2 100%),
      url(../assets/login-bg.png), 0% 0% no-repeat padding-box;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100%;
    @media only screen and(max-width: 1024px){
      height: fit-content !important;
    }
    p{
      @media only screen and(max-width: 1024px){
        font-size: 16px;
      }
    }
    .login-voyager-logo{
      @media only screen and(max-width: 767px){
        width: 150px;
      }
    }
  &-login{
    &-control{
    }
  }
  }
}
