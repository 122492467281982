//Home page start
.doctor-dashboard {
  .tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -60px;
  }

  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
  .table-responsive {
    margin: 30px 0;
  }
  .table-wrapper {
    min-width: 1000px;
    background: #fff;
    padding: 20px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  }
  .table-title {
    padding-bottom: 10px;
    margin: 0 0 10px;
    min-width: 100%;
  }
  .table-title h2 {
    margin: 8px 0 0;
    font-size: 22px;
  }
  .search-box {
    position: relative;
    float: right;
  }
  .search-box input {
    height: 34px;
    border-radius: 20px;
    padding-left: 35px;
    border-color: #ddd;
    box-shadow: none;
  }
  .search-box input:focus {
    border-color: #3fbae4;
  }
  .search-box i {
    color: #a0a5b1;
    position: absolute;
    font-size: 19px;
    top: 8px;
    left: 10px;
  }
  table.table tr th,
  table.table tr td {
    border-color: #e9e9e9;
  }
  table.table-striped tbody tr:nth-of-type(odd) {
    background-color: #fcfcfc;
  }
  table.table-striped.table-hover tbody tr:hover {
    background: #f5f5f5;
  }
  table.table th i {
    font-size: 13px;
    margin: 0 5px;
    cursor: pointer;
  }
  table.table td:last-child {
    width: 130px;
  }
  table.table td a {
    color: #a0a5b1;
    display: inline-block;
    margin: 0 5px;
  }
  table.table td a.view {
    color: #03a9f4;
  }
  table.table td a.edit {
    color: #ffc107;
  }
  table.table td a.delete {
    color: #e34724;
  }
  table.table td i {
    font-size: 19px;
  }
}

//Home Page End

//Patients List page Start
.patients-list {
  .main-box.no-header {
    padding-top: 20px;
  }
  .main-box {
    background: #ffffff;
    -webkit-box-shadow: 1px 1px 2px 0 #cccccc;
    -moz-box-shadow: 1px 1px 2px 0 #cccccc;
    -o-box-shadow: 1px 1px 2px 0 #cccccc;
    -ms-box-shadow: 1px 1px 2px 0 #cccccc;
    box-shadow: 1px 1px 2px 0 #cccccc;
    margin-bottom: 16px;
    -webikt-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
  }
  .table a.table-link.danger {
    color: #e74c3c;
  }
  .label {
    border-radius: 3px;
    font-size: 0.875em;
    font-weight: 600;
  }
  .user-list tbody td .user-subhead {
    font-size: 0.875em;
    font-style: italic;
  }
  .user-list tbody td .user-link {
    display: block;
    font-size: 1.25em;
    padding-top: 3px;
    margin-left: 60px;
  }
  a {
    color: #3498db;
    outline: none !important;
  }
  .user-list tbody td > img {
    position: relative;
    max-width: 50px;
    float: left;
    margin-right: 15px;
  }
  tr.collapse.in {
    display: table-row;
  }
  .table thead tr th {
    text-transform: uppercase;
    font-size: 0.875em;
  }
  .table thead tr th {
    border-bottom: 2px solid #e7ebee;
  }
  .table tbody tr td:first-child {
    font-size: 1.125em;
    font-weight: 300;
  }
  .table tbody tr td {
    font-size: 0.875em;
    vertical-align: middle;
    border-top: 1px solid #e7ebee;
    padding: 12px 8px;
  }
  a:hover {
    text-decoration: none;
  }
}

.specific-patient-search {
  input[type="text"] {
    // position: relative;
  }
  .cancel {
    position: absolute;
    top: 30%;
    left: -20%;
    cursor: pointer;
  }
}
