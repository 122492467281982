@tailwind base;
@tailwind components;
@tailwind utilities;
.ant-layout-sider {
  @apply bg-blue-100 !important;
  background: #003650 !important;
}

.ant-layout-sider-trigger {
  @apply bg-blue-200 !important;
  color: #ffffff !important;
  display: none;
}

.ant-layout-header {
  /*@apply bg-blue-100 !important;*/
  /*padding: 0px !important;*/
  height: auto !important;
  min-height: 64px !important;
  padding: 0 50px;
  /*color: rgba(0, 0, 0, 0.85);*/
  line-height: 64px;
  /*background: #001529;*/
}

.ant-menu {
  /*@apply bg-blue-100 !important;*/
  /* color: #0D92D2; */
  background-color: transparent !important;
}

.ant-picker-cell-inner {
  position: relative !important;
}

.ant-picker-cell-inner > .ant-picker-calendar-date-content {
  position: absolute !important;
  top: 0;
  transform: translate(14px, -69%);
  z-index: 10;
}

.ant-tabs {
  width: 100%;
}

.ant-tabs-tab-active {
  background-color: #fff;
}

.ant-btn {
  display: flex;
  align-items: center;
}

.ant-design > span {
  align-self: center;
  justify-content: center;
  align-items: center;
}

::-webkit-scrollbar {
  width: 0.5em;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.family-history .ant-collapse-content .ant-collapse-content-box {
  padding: 0 16px 0 20px;
}

.ant-carousel .slick-dots li button {
  color: transparent !important;
  background: transparent !important;
}

.ant-spin-nested-loading {
  width: 100%;
}

.ant-empty-image > img {
  margin: auto;
}
.header {
  background: #003650 !important;
}
.header .ant-menu-item {
  padding: 0px 12px;
}

.bg-screen .ant-table-row {
  background-color: #ebf8ff;
}

.bg-screen .ant-table-thead {
  background-color: #90cdf4;
}

.patient-nav ul {
  background-color: transparent !important;
  margin-left: 2%;
}

.ant-menu .ant-menu-item {
  background-color: transparent !important;
  color: #0d92d2;
}

.ant-menu-item-selected {
  color: white !important;
}
.ant-menu {
  /* background-color: white !important; */
}
.ant-menu-item-selected {
  border: none !important;
}
.ant-menu-item-only-child {
  margin: 0 10px !important;
}
.ant-menu-item-only-child:hover {
  border: none;
}
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  margin-right: 6px !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  border: none !important;
  color: white !important;
}
.ant-menu-horizontal {
  border-bottom: none !important;
}
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  top: 0 !important;
}
.ant-avatar {
  background: none !important;
  background-image: url("../../assets/user.png") !important;
  background-size: cover !important;
  background-position: center center;
  background-repeat: no-repeat;
}
.header {
  background: #003650 !important;
}
.bp-readings .ant-table-thead > tr > th {
  background: #3193d2;
}
