.bg-blue {
  background-color: $navbar-bg-blue !important;
  color: $navbar-text-color;
}
.col-width {
  flex: 0 0 0.333333% !important;
}
aside {
}
.sideNav-active-tab {
  color: white;
}
.navbar-icon-box {
  width: 1em;
}
.btn-navbar {
  background-color: transparent;
  border-color: transparent;
  padding: 4px;

  color: $navbar-btn-color-blue;
  :hover {
    color: $navbar-text-color;
  }
  &:focus {
    outline: 0 !important;
    color: white;
    background-color: $navbar-btn-color-blue !important;
    border-radius: 2px !important;
    border: 2px solid $navbar-btn-color-blue !important;
  }
}
.is-active {
  background-color: #21608a;

  border-radius: 5px;
}
.dynamic-sidebar {
  height: auto;
  width: 12vw !important;
  flex: 0 0 12vw !important;
  max-width: 12vw !important;
  min-width: 12vw !important;
}
.ant-menu-vertical,
.ant-menu-inline {
  border-right: none !important;
}
