.main-dashboard {
  .ant-spin-nested-loading {
    height: 100%;
  }
  .ant-spin-container {
    height: 100%;
  }
}
//Medication
.medication_modal_dialog {
  overflow-y: initial !important;
  // max-width: 70vw;
  margin: 1.75rem auto;

  .medication_modal_body {
    height: 100%;
    overflow-y: auto;
  }
}

//Product Ads
.dashboard-devices-name {
  font-size: 0.9rem;
  font-weight: bold;
}

//Vitals

//Media Quries
// @media only screen and (min-width: 992px) {
.dashboard-vital-filter {
  color: #0d92d2 !important;
  background-color: white !important;
  border: 1px solid #0d92d2 !important;
  text-align: center;
  border-radius: 13px !important;
  // padding-left: 7px !important;
  // padding-right: 7px !important;
  font-size: 100% !important;
  // padding-top: 10px !important;
  // padding-bottom: 10px !important;
  width: 22%;
}
.dashboard-vital-filter-active {
  color: white !important;
  background-color: #0d92d2 !important;
  border: 1px solid #0d92d2 !important;
  border-radius: 13px !important;
  text-align: center;
  font-weight: bold;
  // padding-left: 7px !important;
  // padding-right: 7px !important;
  font-size: 100% !important;
  // padding-top: px !important;
  // padding-bottom: 10px !important;
  width: 22%;
}
// }
// .exportChart {
//   width: 790px;
// }
@media screen and (min-width: 1400px) {
  .medication-font-size {
    .medication-name {
      color: #21608A !important;
      font-size: 1.1rem;
    }
    .date {
      color: #21608A;
      font-size: 1rem;
    }
    .view-btn {
      background: #21608A;
      padding: 0 10% !important;
border-radius: 10px !important;
    }
  }
}
@media screen and (min-width: 1900px) {
  .dashboard-vital-filter {
    color: #0d92d2 !important;
    background-color: white !important;
    border: 1px solid #0d92d2 !important;
    text-align: center;
    border-radius: 13px !important;
    // padding-left: 7px !important;
    // padding-right: 7px !important;
    font-size: 140% !important;
    // padding-top: 10px !important;
    // padding-bottom: 10px !important;
    width: 22%;
  }
  .dashboard-vital-filter-active {
    color: white !important;
    background-color: #0d92d2 !important;
    border: 1px solid #0d92d2 !important;
    border-radius: 13px !important;
    text-align: center;
    font-weight: bold;
    // padding-left: 7px !important;
    // padding-right: 7px !important;
    font-size: 150% !important;
    // padding-top: px !important;
    // padding-bottom: 10px !important;
    width: 22%;
  }
}
