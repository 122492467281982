.example {
  margin: 1em 0;
  text-align: center;
}

.example img {
  width: 6vw;
  height: 6vw;
}

.example-cover img {
  object-fit: cover;
}

.btnImgColor {
  background-color: #e7e9ea;
}
.btnImgColor-hover {
  background-color: #d1d3d4;
}

@media screen and (min-width: 1900px) {
  .historic-chart-width {
    width: 90%;
  }
}
