input[type="submit"],
input[type="date"],
input[type="time"],
.datePicker,
.input-md {
  padding-left: 10px !important;
  width: 100%;
  float: none;
  padding: 0px;
  background-color: white;
  border: 1px solid $blue-primary !important;
  border-radius: 10px !important;
  // margin-top: 14px;
  font-size: 15px;
  // line-height: 44px;
  height: 40px !important;
  color: $gray-primary;
  box-shadow: 0px 3px 6px #68686833;
  cursor: pointer;
}
input::placeholder {
  font-size: 11px !important;
}
input[type="button"] {
  &:focus {
    border: 0 none !important;
    button:focus {
      outline: none !important;
      outline: none -webkit-focus-ring-color !important;
    }
  }
}
button::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner {
  padding: 0 !important;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield !important;
}

input[type="select"]::placeholder {
  font-size: 11px !important;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
}

select::-ms-expand {
  display: none;
}

select + i.fa {
  float: right;
  margin-top: -26px;
  margin-right: 10px;
  /* this is so when you click on the chevron, your click actually goes on the dropdown menu */
  pointer-events: none;
  /* everything after this is just to cover up the original arrow */
  /* (for browsers that don't support the syntax used above) */
  background-color: transparent;
  color: $blue-primary !important;
  padding-right: 5px;
}

select option {
  padding-right: 21px;
}
.vital-info-field-head {
  color: $purple-primary !important;
}
input[type="radio"] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid darkgray;
  border-radius: 50%;
  outline: none;
  box-shadow: 0 0 5px 0px gray inset;
}
input[type="radio"]:hover {
  box-shadow: 0 0 5px 0px orange inset;
}
input[type="radio"]:before {
  content: "";
  display: block;
  width: 60%;
  height: 60%;
  margin: 20%;
  border-radius: 50%;
}
input[type="radio"]:checked:before {
  background: $blue-primary;
}

.add-info-btn {
  color: $blue-primary !important;
  background-color: white !important;
  border: 1px solid $blue-primary !important;
  border-radius: 22px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  font-size: 1rem !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;

  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0d92d233;
  opacity: 1;
}

// Modal Style
.modal-title {
  color: $purple-primary !important;
}
.modal-dialog {
  max-width: 800px;
  // margin: 1.75rem auto;
}
.modal-save-btn {
  color: $blue-primary !important;
  background-color: white !important;
  border: 1px solid $blue-primary !important;
  border-radius: 22px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  font-size: 1rem !important;
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0d92d233;
  opacity: 1;
}

.modal-cancel-btn {
  color: red !important;
  background-color: white !important;
  border: 1px solid red !important;
  border-radius: 22px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  font-size: 12px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0d92d233;
  opacity: 1;
}

button:focus {
  outline: 0;
}
.modal-grid-row-12 {
  padding: 0px 5px 0px 5px;
}
.add-btn {
  width: 100%;
  display: inline-block;
  font-weight: 400;
  color: #1d77d2;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  border: 2px dashed #1d77d2;
  padding: 0.375rem 0.75rem;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  width: 100% !important;
}
.remove-btn {
  display: inline-block;
  font-weight: 400;
  color: red;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  border: 2px dashed red;
  padding: 0.375rem 0.75rem;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  width: 100% !important;
}
/*//////////////////////////////////////////////////////////////////
[ Table ]*/
.panel {
  table {
    border-spacing: 1;
    border-collapse: collapse;
    background: white;
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    position: relative;
  }

  table thead {
    background-color: $blue-primary;
    border-radius: 10px;

    th {
      vertical-align: middle;
      border-bottom: 0px solid #dee2e6 !important;
      //font-family: monsterat !important;
      font-weight: normal !important;
      color: white !important;
      height: 50px;
    }
  }

  tbody tr td {
    border: 0.5px solid gainsboro;
  }

  table {
    border-spacing: 1;
    border-collapse: collapse;
    background: white;
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    position: relative;
  }
  table * {
    position: relative;
  }
  table td,
  table th {
    padding-left: 8px;
  }
  table thead tr {
    height: 50px;
    vertical-align: middle;
    background: $blue-primary;
  }
}
//Big Modals
.modal-fading {
  background: rgba(42, 48, 43, 0.6);
  transition: all 0.9s ease-out;
  // transition: opacity 1.15s linear;
}
.big_modal_dialog {
  overflow-y: initial !important;
  transition: all 0.9s ease-out;
  // transition: opacity 1.15s linear;

  .modal-body {
    max-height: 75vh !important;
    overflow-y: auto !important;
  }
}
//Deletion Modal

.fading {
  background: rgba(42, 48, 43, 0.72);
}
.modal-confirm {
  color: #636363;
  max-width: 24rem;
}
.modal-confirm .modal-content {
  padding: 20px;
  border-radius: 5px;
  border: none;
  text-align: center;
  font-size: 14px;
}
.modal-confirm .modal-header {
  border-bottom: none;
  position: relative;
}
.modal-confirm h4 {
  text-align: center;
  font-size: 26px;
  margin: 30px 0 -10px;
}
.modal-confirm .close {
  position: absolute;
  top: -5px;
  right: -2px;
}
.modal-confirm .modal-body {
  color: #999;
}
.modal-confirm .modal-footer {
  border: none;
  text-align: center;
  border-radius: 5px;
  font-size: 13px;
  padding: 10px 15px 25px;
}
.modal-confirm .modal-footer a {
  color: #999;
}
.modal-confirm .icon-box {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  border-radius: 50%;
  z-index: 9;
  text-align: center;
  border: 3px solid #dc3545;
}
.modal-confirm .icon-box i {
  color: #dc3545;
  font-size: 46px;
  display: inline-block;
  margin-top: 13px;
}
.modal-confirm .btn,
.modal-confirm .btn:active {
  color: #fff;
  border-radius: 4px;
  // background: #60c7c1;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  min-width: 120px;
  border: none;
  min-height: 40px;
  border-radius: 3px;
  margin: 0 5px;
}
.modal-heading {
  color: #636363;
}
.modal-confirm .btn-secondary {
  background: #c1c1c1;
}
.modal-confirm .btn-secondary:hover,
.modal-confirm .btn-secondary:focus {
  background: #a8a8a8;
}
.modal-confirm .btn-danger {
  background: #dc3545;
}
.modal-confirm .btn-danger:hover,
.modal-confirm .btn-danger:focus {
  background: #ee3535;
}
.trigger-btn {
  display: inline-block;
  margin: 100px auto;
}

//Media Quries
/* Extra small devices (phones, 600px and down) */
// @media only screen and (max-width: 600px) {
//   .login-background {
//     height: 100%;
//   }
// }
//
// /* Small devices (portrait tablets and large phones, 600px and up) */
// @media only screen and (min-width: 600px) {
//   .login-background {
//     height: 100%;
//   }
// }
//
// /* Medium devices (landscape tablets, 768px and up) */
// @media only screen and (min-width: 768px) {
//   .login-background {
//     height: 100%;
//   }
// }
//
// /* Large devices (laptops/desktops, 992px and up) */
// @media only screen and (min-width: 992px) {
//   .login-background {
//     height: 95vh;
//   }
// }
//
// /* Extra large devices (large laptops and desktops, 2560px and up) */
// @media (min-width: 1920px) and (max-width: 2560px) {
// }
