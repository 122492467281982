@font-face {
  font-family: segoeBold;
  src: url(../assets/Fonts/Segoe-UI-Bold.woff);
  color: inherit !important;
}
@font-face {
  font-family: segoe;
  src: url(../assets/Fonts/Segoe-UI.woff);
  color: inherit !important;
}

body {
  //font-family: monsterat !important;
}